














































































































import Vue from 'vue'

import draggable from 'vuedraggable'
import Switcher from '../components/Switcher.vue'
import CopyClipboardModal from '../components/interests/CopyClipboardModal.vue'
import TabSwitcher from '../components/TabSwitcher.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'

export default Vue.extend({
  name: 'InterestsExploreSelectedLayering',
  data () {
    return {
      isBlock: true,
      layers: [],
      transformState: false,
      chooseHandle: false,
      copyData: [],
      menu: [
        { id: 0, name: '', title: 'Randomly' },
        { id: 1, name: '', title: 'Size' }
      ],
      activeTab: {}
    }
  },
  components: {
    draggable,
    Switcher,
    CopyClipboardModal,
    TabSwitcher
  },
  watch: {
    layerChecking (v) {
      this.setStateField({
        field: 'appliedLayeringChanges',
        value: !v
      })
    }
  },
  computed: {
    ...mapState('interests', [
      'interestsCollection',
      'appliedLayeringChanges',
      'dividingLimit'
    ]),
    ...mapGetters('interests', [
      'getInterestsCollectionLayering',
      'getInitialInterestsCollectionLayering',
      'getInterestCollectionSending']),
    layerChecking () {
      return this.layers.filter(v => v.status).length === this.layers.filter(v => v.status && v.data.length > 1).length
    },
    dragOptions () {
      return {
        animation: 0,
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    rightLayers () {
      const lay = []
      this.layers.forEach((item, index) => {
        item.data.forEach(it => {
          it.layer = String(index + 1)
          lay.push(it)
        })
      })
      return lay
    }
  },
  methods: {
    ...mapMutations('interests', [
      'deleteCollectionItem',
      'setStateField',
      'divideInterestsCollection'
    ]),
    choose () {
      this.chooseHandle = true
    },
    start () {
      this.transformState = true
    },
    end () {
      this.chooseHandle = false
      this.transformState = false
      //  this.setStateField({ field: 'appliedLayeringChanges', value: false })
    },
    changeLayering () {
      this.setStateField({
        field: 'appliedLayeringChanges',
        value: false
      })
      this.setStateField({
        field: 'interestsCollection',
        value: this.rightLayers
      })
    },
    divideItems (payload: { activeTab: { title : string }}) {
      this.activeTab = payload.activeTab
      this.divideInterestsCollection(payload.activeTab.title.toLowerCase())
      this.setStateField({ field: 'appliedLayeringChanges', value: false })
      this.activeTab = {}
    },
    showClipboardModal (ind) {
      if (this.layers[ind].data.length > 0) {
        const arr = []
        this.layers[ind].data.forEach(item => {
          arr.push(item.name)
        })
        this.copyData = arr
        this.$modal.show('copy-clipboard-modal')
      }
    },
    toggleLayerStatus (ind) {
      this.layers[ind].status = !this.layers[ind].status
      this.setStateField({
        field: 'dividingLimit',
        value: this.layers.filter(item => item.status).length
      })
    },
    // apllyChanges () {
    //   this.setStateField({
    //     field: 'interestsCollection',
    //     value: this.rightLayers
    //   })
    //   this.isBlock = true
    //   // this.setStateField({ field: 'appliedLayeringChanges', value: false })
    // },
    deleteItem (audienceId) {
      this.setStateField({
        field: 'appliedLayeringChanges',
        value: false
      })
      this.deleteCollectionItem(audienceId)
    }
  },
  mounted () {
    this.layers = this.getInterestsCollectionLayering
    this.setStateField({
      field: 'dividingLimit',
      value: this.layers.filter(item => item.status).length
    })
  },
  updated () {
    this.layers = this.getInterestsCollectionLayering
  }
})
