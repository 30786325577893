














import Vue from 'vue'

import Modal from '../../components/Modal.vue'
import BaseTextarea from '../BaseComponents/BaseTextArea/BaseTextArea.vue'

export default Vue.extend({
  name: 'CopyClipboardModal',
  components: { Modal, BaseTextarea },
  props: {
    title: {
      type: String
    },
    copyData: {
      type: Array
    }
  },
  methods: {
    copyClipboard () {
      const el = document.createElement('textarea')
      el.value = this.copyData
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.opacity = '0'
      el.style.bottom = '0'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      this.$modal.hide('copy-clipboard-modal')
      this.$store.dispatch('notifications/addNotification', { id: Date.now(), body: this.copyData + ' copied to clipboard', type: 'success' })
    }
  }
})
